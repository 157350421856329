/* eslint-disable padded-blocks */
/* eslint-disable curly */
/* eslint-disable spaced-comment */
/* eslint-disable quotes */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
// a library to wrap and simplify api calls
import apisauce from 'apisauce';

const create = (baseURL = 'https://aprilaccessories.azurewebsites.net/') => {

  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'application-content': 'jsonp',
      'Access-Control-Allow-Origin': 'true',
    },
    timeout: 1000000,
  });


  const refreshToken = async () => {
    let refresh_token = localStorage.getItem('refreshToken');
    let userId = localStorage.getItem('userId');

    const result = await api.post('/api/Token/Refresh', { previousRefreshToken: refresh_token, userId });
    setLocalData(result);
  }


  const getUsers = async () => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get('/api/Users', {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const getCurrentAdmin = async (id) => {
    const adminId = localStorage.getItem('adminId');
    const accessToken = localStorage.getItem('accessToken');
    return api.get(`/api/Admins/${adminId}`, { id: adminId }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const makeAdmin = async (userId) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post('/api/Admins', { userId, isOwner: false }, { headers: { Authorization: `Bearer ${accessToken}` } })
  };
const deleteAdmin = async (userId) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.delete(`/api/Admins/${userId}`,undefined, { headers: { Authorization: `Bearer ${accessToken}` } })
  };
  const addToWholesalers = async (userId) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.put(`/api/Users/${userId}/wholesaler`,undefined, { headers: { Authorization: `Bearer ${accessToken}` } })
  };
  const deleteFromWholesalers = async (userId) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.delete(`/api/Users/${userId}/wholesaler`,{}, { headers: { Authorization: `Bearer ${accessToken}` } })
  };
  const userLogin = ({ mobile_number, password }) => api.post('api/token', { phonenumber: mobile_number, password, IsAdminPanel: true });
  const getRoot = () => api.get('')
  const getRate = () => api.get('rate_limit')
  // const getUser = (username) => api.get('search/users', { q: username })
  const getUser = (userID) => api.get(`/api/Users/${userID}`);

  /***********Coupons */

  const createCoupon = async (data) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post(`/api/Coupons`, data, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const getCoupons = async () => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get(`api/Coupons`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  };

  const deleteCoupon = async (id) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.delete(`api/Coupons/${id}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }
  /***********Coupons */

  const getCategories = async () => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get(`api/Categories`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  };
  const getCategoryWithId = async (id) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get(`api/Categories/${id}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }
  const deleteCategory = async (id) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.delete(`api/Categories/${id}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const publishCategory = async (categoryIds) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post(`/api/Categories/publish`, { categoryIds }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const depublishCategory = async (categoryIds) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post(`/api/Categories/depublish`, { categoryIds }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const updateCategory = async ({ id, parentCategoryId, isActive, name, coverPhoto, sortIndex }) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.put(`api/Categories/${id}`, { sortIndex, parentCategoryId, isActive, name, description: '', coverPhoto }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const postUserData = ({ username, phone, nickname, region_id, city_id, area_id, address, password }) =>
    api.post('/api/Users', { PhoneNumber: phone, username: phone, Password: password, firstName: nickname, secondName: nickname, familyName: nickname, region_id, cityId: "7EB2CFC3-9233-46DC-8ACC-F894E8BE5D02", area_id, AddressDetails: 'dummyDetails' });

  const putUser = (body) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.put(`/api/Users/${body.id}`, body, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  /**************** Categories services *************/
  const createCategory = async ({ sortIndex, name, isActive, description, parentCategoryId, location }) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post(`/api/Categories`, { sortIndex, name, isActive, description, parentCategoryId, coverPhoto: location }, { headers: { Authorization: `Bearer ${accessToken}` } })
  };

  /************************* Products **********************/
  const createProduct = async ({ name, description, price, wholesalePrice, isActive, cost, brand, code, categoryIds, productOptions, productPhotos, productInfo }) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post(`/api/Products`, { name, description, price, wholesalePrice, isActive, cost, brand, code, categoryIds, productOptions, productPhotos, productInfo }, { headers: { Authorization: `Bearer ${accessToken}` } })
  };
  const updateProduct = async ({ name, description, price, wholesalePrice, isActive, cost, brand, code, categoryIds, productOptions, productPhotos, productInfo }, id) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.put(`/api/Products/${id}`, { name, description, price, wholesalePrice, isActive, cost, brand, code, categoryIds, productOptions, productPhotos, productInfo }, { headers: { Authorization: `Bearer ${accessToken}` } })
  };

  const deleteProduct = async (id) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.delete(`api/Products/${id}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const publishProduct = async (productIds) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post(`/api/Products/publish`, { productIds }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }
  const depublishProduct = async (productIds) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post(`/api/Products/depublish`, { productIds }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const makeProductBestSeller = async (productIds, isBestSeller) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post(`/api/Products/bestSeller`, { productIds, isBestSeller }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const getAllProducts = async () => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get(`/api/Products`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }
  const getRegions = async () => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get(`/api/Areas`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }
  const getCities = async (governoratesId) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get(`/api/Governorates/area/${governoratesId}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }
  const getAreas = async (cityId) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get(`/api/Cities/governorate/${cityId}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const getProductsWithCategoryId = async (id) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get(`/api/Products/${id}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const getProductByCode = async (code) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get(`/api/products/code/${code}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const getBannerPhotos = () => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get('/api/Banners', {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const postBannerPhoto = (url, isWholesaler) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post('/api/Banners', { url, isWholesaler }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const deleteBannerPhoto = (id) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.delete(`/api/Banners/${id}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const getAllOrders = () => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get('/api/Orders', {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const createOrder = async (data) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post(`api/Orders`, data, { headers: { Authorization: `Bearer ${accessToken}` } });
  }
  const createUserAddress = async (data) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post(`/api/Addresses`, data, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const getAllArchivedOrders = () => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get('/api/Orders/Archived', {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }
  const getFirstArchivedOrders = (amount) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get(`/api/Orders/Archived/${amount}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const updateOrder = ({ orderId, status, notes }) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.put(`/api/Orders/${orderId}`, { status, notes }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const massUpdateOrderStatus = ({ status, orderIds }) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.put(`/api/Orders/`, { status, orderIds }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const exportOrders = () => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get('/api/Orders/Export/', {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  };
  // ==============================
  const getAllPolicies = () => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get('/api/Policies', {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const postPolicy = ({ title, description }) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post('/api/Policies', { title, description }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const updatePolicy = (id, { title, description }) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.put(`/api/Policies/${id}`, { title, description }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const deletePolicy = (id) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.delete(`/api/Policies/${id}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const getOptionsSet = () => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post(`/api/OptionSet/`, { optionTypes: ['OrderStatus', 'UserType', 'Gender', 'CouponType'] }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const getAllAreas = () => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get(`/api/Areas/`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const deleteArea = (id) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.delete(`/api/Areas/${id}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const addArea = (data) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post(`/api/Areas/`, { ...data }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const updateArea = (data, id) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.put(`/api/Areas/${id}`, { ...data }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const getGovernorates = (id) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.get(`/api/Governorates/area/${id}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const createGovernorate = (data) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.post(`/api/Governorates`, { ...data }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const updateGovernorate = (data, id) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.put(`/api/Governorates/${id}`, { ...data }, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  const deleteGovernorate = (id) => {
    const accessToken = localStorage.getItem('accessToken');
    return api.delete(`/api/Governorates/${id}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
  }

  function setLocalData(refreshResult) {
    if (refreshResult.data.accessToken)
      localStorage.setItem('accessToken', refreshResult.data.accessToken);
    if (refreshResult.data.refreshToken)
      localStorage.setItem('refreshToken', refreshResult.data.refreshToken);
    if (refreshResult.data.userId)
      localStorage.setItem('userId', refreshResult.data.userId);
    if (refreshResult.data.adminId)
      localStorage.setItem('adminId', refreshResult.data.adminId);
    localStorage.setItem('tokenGetIn', new Date());
    if (refreshResult.data.expiresIn)
      localStorage.setItem('expiresIn', refreshResult.data.expiresIn);
  }

  return {

    getRoot,
    getRate,
    getUser,

    putUser,

    userLogin,
    getUsers,
    getCurrentAdmin,
    makeAdmin,
    deleteAdmin,
    addToWholesalers,
    deleteFromWholesalers,

    postUserData,

    createCategory,
    getCategories,
    deleteCategory,
    getCategoryWithId,
    publishCategory,
    depublishCategory,
    updateCategory,

    refreshToken,

    createProduct,
    updateProduct,
    getAllProducts,
    getRegions,
    getCities,
    getAreas,
    deleteProduct,
    publishProduct,
    depublishProduct,
    getProductsWithCategoryId,
    getProductByCode,
    makeProductBestSeller,

    getBannerPhotos,
    postBannerPhoto,
    deleteBannerPhoto,

    getAllPolicies,
    postPolicy,
    deletePolicy,
    updatePolicy,

    createCoupon,
    getCoupons,
    deleteCoupon,

    getAllOrders,
    createOrder,
    getAllArchivedOrders,
    getFirstArchivedOrders,
    createUserAddress,
    updateOrder,
    massUpdateOrderStatus,
    exportOrders,

    getOptionsSet,

    getAllAreas,
    addArea,
    updateArea,
    deleteArea,

    getGovernorates,
    createGovernorate,
    updateGovernorate,
    deleteGovernorate,


  };
};

// let's return back our create method as the default.
export default {
  create,
};
