import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import SignInPage from 'containers/SignInPage/Loadable';
import SignUpPage from 'containers/SignUpPage/Loadable';
import DashboardPage from 'containers/DashboardPage/Loadable';
import Api from '../../utils/Api';

// max-width: calc(768px + 16px * 2);
const AppWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  height: 100hv;
  padding: 0 16px;
  flex-direction: column;
`;

function AutherizationRedirector({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={
        (props) => authed === true
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />}
    />
  );
}

export default class App extends Component {
  constructor(props) {
    super(props);
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      this.validAccessToken = true;
    }
    else {
      this.validAccessToken = false;
    }
  }
  componentDidMount() {
    const api = Api.create();
    if(this.validAccessToken){
    api.refreshToken();
    }
    setInterval(() => {
      if(this.validAccessToken){
        api.refreshToken();
      }
    }, 115000);
  
  }
  render() {
    return (
      <AppWrapper>
        <Helmet
          titleTemplate="%s - April Accessories"
          defaultTitle="April Accessories"
        >
          <meta name="description" content="A React.js Boilerplate application" />
        </Helmet>
        {/* <Header /> */}
        <Switch>
          <AutherizationRedirector authed={this.validAccessToken} exact path="/" component={() => <Redirect to={{ pathname: '/dashboard' }} />} />
          {/* <AutherizationRedirector authed={this.validAccessToken} path="/dashboard" component={() => <DashboardPage />} />
          <AutherizationRedirector authed={!this.validAccessToken} path="/signin" component={() => <SignInPage />} /> */}
          {/* <Route path="/signin" component={SignInPage} /> */}
          <Route path="/signup" component={SignUpPage} />
          <Route path="/signin" component={SignInPage} />
          <Route path="/dashboard" component={DashboardPage} />
        </Switch>
      </AppWrapper >
    );
  }
}
